<template>
<div class="p-2 burgerButton">
        <label  class=" md:block">
            <span class="sr-only">Open main menu</span>
            <div class="flex gap-2 justify-center">
                
                <div class="relative group">
                <button @click="toggleHandler" aria-label="Menu button" class="relative group">
                    
                        <div class="relative flex overflow-hidden items-center justify-center rounded-full w-[50px] h-[50px] transform transition-all ring-0 ring-gray-300 hover:ring-8  ring-opacity-30 duration-200 shadow-md" :class="[isNavigationOpen?'ring-4':'', computedColorMode.bg]">
                            <div class="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
                                <div class="h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:" :class="[isNavigationOpen?'translate-x-10':'', computedColorMode.icon]"></div>
                                <div class="h-[2px] w-7 rounded transform transition-all duration-300 group-focus: delay-75" :class="[isNavigationOpen?'translate-x-10':'', computedColorMode.icon]"></div>
                                <div class="h-[2px] w-7 transform transition-all duration-300 origin-left group-focus: delay-150" :class="[isNavigationOpen?'translate-x-10':'', computedColorMode.icon]"></div>
                                
                                <div class="absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10  flex w-0 " :class="[isNavigationOpen?'translate-x-0 w-12':'', computedColorMode.icon]">
                                    <div class="absolute h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300 " :class="[isNavigationOpen?'rotate-45':'', computedColorMode.icon]"></div>
                                    <div class="absolute h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300" :class="[isNavigationOpen?'-rotate-45':'', computedColorMode.icon]"></div>
                                </div>
                            </div>
                        </div>
             
                    </button>
                </div>
            </div>

        </label>
</div>

</template>

<script lang="ts">

export default defineNuxtComponent({
    name: 'componentBurgerButton',
    props:{
        colorMode: {
            type: String as () => 'primary' | 'secondary',
            default: "primary"
        }
    },
    computed:{
        computedColorMode(){
            if(this.colorMode == "secondary"){
                return {
                    bg: "bg-white",
                    text: "text-evellePrimary",
                    fill: "fill-evellePrimary",
                    icon: "bg-evellePrimary",
                }
            }else{
                return {
                    bg: "bg-evellePrimary",
                    text: "text-white",
                    fill: "fill-white",
                    icon: "bg-white",
                }
            }
        }
    },
    methods:{
        toggleHandler(){
            this.isNavigationOpen = !this.isNavigationOpen;
        }
    },
    setup() {
       const isNavigationOpen = useState('isNavigationOpen'); 

       return {isNavigationOpen}
    }
})
</script>

<style lang="scss"></style>